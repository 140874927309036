import { useCallback, useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { useMutation, ApolloError } from '@apollo/client';
import Cropper, { Area } from 'react-easy-crop';

import { saveAs } from 'file-saver';
import { FileRejection } from 'react-dropzone';

import { error } from '@app/snackbars';
import { Loader } from '@app/ui/loader';
import { apolloClient, httpClient } from '@app/query/configs';
import { UploadDropZone, UploadDropZoneProps } from '@app/ui/forms';
import { Modal } from '@app/ui/modal';

import { File as FileDto } from '../types';
import { FILE_CREATE_ONE_WITH_PRE_SIGNED_POST, FILE_DELETE_ONE, FILE_DOWNLOAD_ONE } from '../gql';
import { getCroppedImg } from '../utils';

const FILE_MAX_SIZE = 5242880;

const deleteFile = (id: number) =>
  apolloClient.mutate<Record<any, any>>({
    mutation: FILE_DELETE_ONE,
    variables: {
      id,
    },
  });

export interface UploadFilesProps
  extends Omit<UploadDropZoneProps, 'onDelete' | 'onDownload' | 'onChange' | 'maxFiles'> {
  readonly value: FileDto[];
  readonly type: string;
  readonly maxFiles: number;
  readonly onChange: (files: FileDto[]) => Promise<void>;
  readonly onDeleted?: (file: FileDto) => void;
  readonly onDelete?: (file: FileDto) => void;
  readonly isLoading?: boolean;
  readonly cropperProps?: Record<any, any>;
  readonly disabled?: boolean;
}

export const UploadFiles = memo(
  ({
    value,
    maxFiles,
    onChange,
    onDeleted,
    isLoading = false,
    type,
    onDelete,
    cropperProps,
    ...rest
  }: UploadFilesProps) => {
    const { t } = useTranslation('common');
    const [isInnerLoading, setIsInnerLoading] = useState(false);
    const [isCropLoading, setIsCropLoading] = useState(false);
    const [filesToCrop, setFilesToCrop] = useState<File[]>([]);
    const [currentFileIndex, setCurrentFileIndex] = useState(0);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1.2);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
    const [currentImage, setCurrentImage] = useState<string | null>(null);

    const handleCropComplete = useCallback((_: Area, croppedPixels: Area) => {
      setCroppedAreaPixels(croppedPixels);
    }, []);

    const applyCrop = useCallback(async () => {
      const fileToCrop = filesToCrop[currentFileIndex];

      if (!fileToCrop || !croppedAreaPixels) {
        return;
      }

      setIsCropLoading(true);

      try {
        const croppedImage = await getCroppedImg(fileToCrop, croppedAreaPixels);

        await uploadFiles([croppedImage]);

        if (currentFileIndex + 1 < filesToCrop.length) {
          setCurrentFileIndex(currentFileIndex + 1);
        } else {
          setFilesToCrop([]);
          setCurrentFileIndex(0);
        }
      } catch (e) {
        error(t('general.error.somethingWentWrong'));
      } finally {
        setIsCropLoading(false);
      }
    }, [croppedAreaPixels, filesToCrop, currentFileIndex]);

    const [fileDeleteOne, { loading: fileDeleteLoading }] = useMutation<{ fileDeleteOne: { id: number } }>(
      FILE_DELETE_ONE,
    );

    const uploadFiles = useCallback(
      async (files: File[]) => {
        if ((value?.length || 0) + files.length > maxFiles) {
          error(t('general.validation.maxFiles', { max: maxFiles }));
          return;
        }

        setIsInnerLoading(true);

        try {
          const uploadedFiles = await Promise.all(
            files.map(async file => {
              const { data: response } = await apolloClient.mutate<{
                fileCreateOneWithPreSignedPost: Record<any, any>;
              }>({
                mutation: FILE_CREATE_ONE_WITH_PRE_SIGNED_POST,
                variables: { input: { type, name: file.name } },
              });

              const uploadedFile: FileDto = response?.fileCreateOneWithPreSignedPost.file;

              const formData = new FormData();
              Object.keys(response?.fileCreateOneWithPreSignedPost.signedPost.fields).forEach(key => {
                formData.append(key, `${response?.fileCreateOneWithPreSignedPost.signedPost.fields[key]}`);
              });

              formData.append('file', file);

              try {
                await httpClient.post(response?.fileCreateOneWithPreSignedPost.signedPost.url, formData);

                return uploadedFile;
              } catch (e) {
                deleteFile(uploadedFile.id).catch(err => console.error(err));
                throw e;
              }
            }),
          );

          try {
            await onChange(uploadedFiles);
          } catch (e) {
            Promise.all(uploadedFiles.map(uploadedFile => deleteFile(uploadedFile.id))).catch(err =>
              console.error(err),
            );
            throw e;
          }
        } catch (e) {
          error((e as ApolloError).message || t('general.error.somethingWentWrong'));
        } finally {
          setIsInnerLoading(false);
        }
      },
      [value, maxFiles, type],
    );

    const handleChange = useCallback((files: File[]) => {
      if (files.length > 0) {
        setFilesToCrop(files);
        setCurrentFileIndex(0);
      }
    }, []);

    useEffect(() => {
      if (filesToCrop[currentFileIndex]) {
        const objectUrl = URL.createObjectURL(filesToCrop[currentFileIndex]);
        setCurrentImage(objectUrl);

        return () => {
          URL.revokeObjectURL(objectUrl);
        };
      }
      return () => {};
    }, [filesToCrop, currentFileIndex]);

    const handleDelete = useCallback(
      (file: FileDto) => {
        if (onDelete) {
          onDelete(file);
        } else {
          fileDeleteOne({ variables: { id: file.id } }).then(() => {
            if (onDeleted) {
              onDeleted(file);
            }
          });
        }
      },
      [onDeleted, onDelete],
    );

    const handleDownload = useCallback(async (file: FileDto) => {
      setIsInnerLoading(true);

      try {
        const { data: urlData } = await apolloClient.query<{ fileDownloadOne: { url: string } }>({
          query: FILE_DOWNLOAD_ONE,
          variables: { id: file.id },
        });

        const response = await httpClient({
          method: 'GET',
          url: urlData?.fileDownloadOne.url,
          responseType: 'blob',
        });

        const fileObject = new File([response.data], file.name, { type: response.data.type });

        saveAs(fileObject);
      } catch (e) {
        error((e as ApolloError).message || t('general.error.somethingWentWrong'));
      } finally {
        setIsInnerLoading(false);
      }
    }, []);

    const handleError = useCallback(
      (filesRejection: FileRejection[]) => {
        filesRejection.forEach(({ errors, file }) => {
          errors.forEach(({ code, message }) => {
            let newMessage = message;

            if (code === 'file-too-large') {
              newMessage = t('general.validation.fileTooLarge', {
                file: file.name,
                limit: FILE_MAX_SIZE / 1024 / 1024,
              });
            }

            error(newMessage);
          });
        });
      },
      [t],
    );

    return (
      <Box position="relative">
        <UploadDropZone
          accept={{
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
          }}
          maxSize={FILE_MAX_SIZE}
          maxFiles={maxFiles}
          value={value}
          onChange={handleChange}
          onDelete={handleDelete}
          onDownload={handleDownload}
          onDropRejected={handleError}
          {...rest}
        />
        <Loader invisible position="absolute" isLoading={isLoading || isInnerLoading || fileDeleteLoading} />
        <Modal open={filesToCrop.length > 0} onClose={() => setFilesToCrop([])}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box height="40vh">
              {filesToCrop[currentFileIndex] && (
                <>
                  <Box position="absolute" top="6vh" left="6vh" right="6vh" bottom="10vh">
                    <Cropper
                      aspect={1}
                      {...cropperProps}
                      image={currentImage as any}
                      crop={crop}
                      zoom={zoom}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                    />
                  </Box>
                </>
              )}
            </Box>
            <Button sx={{ width: '50%' }} onClick={applyCrop} variant="contained" color="primary">
              {t('general.button.crop')}
            </Button>
            <Loader position="absolute" isLoading={isCropLoading} />
          </Box>
        </Modal>
      </Box>
    );
  },
);
