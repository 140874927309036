import { PDFViewer } from '@react-pdf/renderer';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { QuestionnairePdfDocument } from '@app/questionnaire/components/pdf/QuestionnairePdfDocument';
import { QuestionnairePdf } from '@app/questionnaire/constants';
import { getPdfFields } from '@app/application/utils/survey';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { questionnaireGetOne } from '@app/questionnaire/gql';
import { Loader } from '@app/ui/loader';

export const AdminParentSingleUserPdfPage = () => {
  const { id, type } = useParams<{ id: string; type: string }>();

  const fields = getPdfFields(
    questionnaireConfig.surveys,
    type as QuestionnairePdf,
    questionnaireConfig.pdf.coverPageFields,
  );

  const { data } = useQuery<{ questionnaireGetOne: Questionnaire }>(questionnaireGetOne(fields), {
    variables: { id: Number(id) },
  });

  return data?.questionnaireGetOne ? (
    <PDFViewer width="100%" height="100%">
      <QuestionnairePdfDocument data={data?.questionnaireGetOne} type={type as QuestionnairePdf} />
    </PDFViewer>
  ) : (
    <Loader isLoading />
  );
};
