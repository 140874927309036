import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { useMutation, useQuery, ApolloError } from '@apollo/client';

import { useAuth } from '@app/auth';
import { Card } from '@app/ui/card';
import { Loader } from '@app/ui/loader';
import { error, success } from '@app/snackbars';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { ApplicationStatusEnum } from '@app/application/constants';

import imgUploadCard from '../../images/card-application-upload.jpg';
import { UploadForm } from './UploadForm';
import { Application } from '../../types/Application';
import { APPLICATION_SUBMIT_PHOTOS, applicationGetOne } from '../../gql';

export const UserUploadPhotos = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { application } = useAuth();

  const { data, loading } = useQuery<{ applicationGetOne: Application }>(
    applicationGetOne(['isPhotosSubmitted', 'status', 'photos.name']),
    {
      variables: { id: application?.id || 0 },
    },
  );

  const [applicationSubmitPhotos, { loading: isSubmitLoading }] = useMutation<{
    applicationSubmitPhotos: Partial<Application>;
  }>(APPLICATION_SUBMIT_PHOTOS);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('application.page.title'),
        to: '/surrogate-application',
      },
      {
        title: t('application.finish.upload.title'),
      },
    ],
    [t],
  );

  const handleBack = useCallback(() => navigate('/surrogate-application'), []);

  const handleFinish = useCallback(async () => {
    try {
      await applicationSubmitPhotos({ variables: { input: { id: application?.id } } });

      handleBack();

      success(t('application.finish.upload.successMessage'));
    } catch (e) {
      error((e as ApolloError).message || t('general.error.somethingWentWrong'));
    }
  }, [t, application?.id]);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />

      <Card size="large" img={imgUploadCard}>
        <Typography variant="h1" gutterBottom>
          {t('application.finish.upload.title')}
        </Typography>
        <Box mb={3}>
          <Typography>{t('application.finish.upload.description.item1')}</Typography>
        </Box>
        {data?.applicationGetOne?.id && (
          <>
            <UploadForm applicationId={data?.applicationGetOne.id} />

            <Box sx={{ display: { sm: 'flex' }, justifyContent: 'flex-end', mt: 4 }}>
              <Button
                disabled={
                  data?.applicationGetOne?.status === ApplicationStatusEnum.applicants ||
                  (data?.applicationGetOne?.photos?.length || 0) < 8
                }
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                variant="contained"
                color="primary"
                onClick={handleFinish}
              >
                {t('application.button.confirmAndFinish.name')}
              </Button>
            </Box>
          </>
        )}
      </Card>

      <Box mt={3}>
        <Button color="primary" onClick={handleBack}>
          {t('application.button.backSurrogateApplication.name')}
        </Button>
      </Box>

      <Loader isLoading={loading || isSubmitLoading} />
    </Box>
  );
};
