import { Loader } from '@app/ui/loader';
import { Box, Typography, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useAuth, useAuthActions } from '@app/auth';
import { getFieldsFromSurveys } from '@app/application/utils/survey';
import { questionnaireConfig } from '@app/questionnaire/configs';
import { useMutation, useQuery } from '@apollo/client';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { QUESTIONNAIRE_SUBMIT, questionnaireGetOne } from '@app/questionnaire/gql';
import { error } from '@app/snackbars';
import { useConfirmation } from '@app/ui/modal';
import { QuestionnaireStatusEnum } from '@app/questionnaire/constants';
import { Alert } from '@app/ui/alert';
import { QuestionnaireList } from './QuestionnaireList';
import { UserFinishQuestionnaire } from '../UserFinishQuestionnaire';

export const QuestionnaireItem = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { questionnaire: currentQuestionnaire } = useAuth();
  const { isLoading } = useAuthActions();
  const [isSuccess, setIsSuccess] = useState(false);

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(questionnaireConfig.surveys);

    return surveyFields;
  }, []);

  const { data, loading } = useQuery<{ questionnaireGetOne: Questionnaire }>(
    questionnaireGetOne([...fields, 'isPhotosSubmitted', 'photos.id']),
    {
      variables: { id: currentQuestionnaire?.id },
      fetchPolicy: 'network-only',
    },
  );

  const [questionnaireSubmit, { loading: isSubmitLoading }] = useMutation<{
    questionnaireSubmit: Partial<Questionnaire>;
  }>(QUESTIONNAIRE_SUBMIT);

  const { firstName = '', partner, isPartnerInformation = false } = data?.questionnaireGetOne || {};

  const handleSurveyClick = useCallback((survey: string) => navigate(`/parent-questionnaire/${survey}`), []);

  const subtitleAdditionalNames = isPartnerInformation
    ? `${firstName} and ${partner?.firstName || ''}`
    : firstName || '';

  const handleSubmit = useCallback(
    async (hideModal: () => void) => {
      try {
        await questionnaireSubmit({ variables: { input: { id: currentQuestionnaire?.id } } });
        // const questionnaire = response?.data?.questionnaireSubmit;
        setIsSuccess(true);

        hideModal();
      } catch (e) {
        error(t('general.error.somethingWentWrong'));
      }
    },
    [t, currentQuestionnaire?.id, questionnaireSubmit, navigate],
  );

  const [confirm] = useConfirmation({
    title: t('questionnaire.modal.title'),
    description: t('questionnaire.modal.description'),
    submitButtonText: t('questionnaire.button.finishAndSubmit.name'),
    onSubmit: handleSubmit,
    isLoading: isSubmitLoading || isLoading,
  });

  return (
    <>
      {isSuccess ? (
        <UserFinishQuestionnaire onBack={() => setIsSuccess(false)} />
      ) : (
        <>
          {data?.questionnaireGetOne?.status &&
            ![QuestionnaireStatusEnum.lead, QuestionnaireStatusEnum.applicants].includes(
              data?.questionnaireGetOne?.status as QuestionnaireStatusEnum,
            ) &&
            (data?.questionnaireGetOne?.photos?.length || 0) < 6 && (
              <Alert severity="info" sx={{ mb: 4 }} inner>
                <Trans
                  i18nKey={t('questionnaire.finish.upload.notification')}
                  components={{
                    a: <Link component={RouterLink} to="/parent-questionnaire/upload-photos" />,
                  }}
                />
              </Alert>
            )}
          <Box
            mb={3}
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h1">{t('questionnaire.page.title')}</Typography>

            <Typography variant="subtitle1">
              {t('questionnaire.page.subtitle', { names: subtitleAdditionalNames })}
            </Typography>
          </Box>

          {data?.questionnaireGetOne && (
            <QuestionnaireList item={data.questionnaireGetOne} onClick={handleSurveyClick} onSubmit={confirm} />
          )}
        </>
      )}

      <Loader isLoading={loading || isLoading} />
    </>
  );
};
