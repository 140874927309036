import { useField } from 'formik';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Text as MuiText, TextProps as MuiTextProps } from '@app/ui/forms/components/Text';

import { useErrorTranslations } from '../../hooks/useErrorTranslations';

export type TextProps = MuiTextProps & {
  readonly name: string;
  readonly withLockIcon?: boolean;
  readonly validation?: (value: any, t?: (key: string, data: { region: string }) => string) => string | undefined;
};

export const Text = memo(({ name, helperText, ...props }: TextProps) => {
  const { t } = useTranslation('common');
  const [{ value, onChange }, { touched, error }, { setTouched }] = useField({
    name,
    ...(props.validation ? { validate: val => props.validation!(val, t) } : {}),
  });

  const errorHelperText = useErrorTranslations(name, error);

  const isError = Boolean(touched && errorHelperText);

  return (
    <MuiText
      {...props}
      value={value}
      name={name}
      onChange={onChange}
      onBlurCapture={() => setTouched(true)}
      error={isError}
      helperText={isError ? errorHelperText : helperText || ''}
    />
  );
});
