import { useTranslation } from 'react-i18next';
import { Page, Text, Image, View, StyleSheet } from '@react-pdf/renderer';

import { palette } from '@app/app/configs/theme';
import { isAnswered } from '@app/dynamic/utils/progress';

import { mainStyles } from '../styles';
import { getName } from '../../../utils';
import { Application } from '../../../types/Application';
import logoSmallWhite from '../images/logo-small-white@2x.png';

const profileFields = [
  'baseFee',
  'deposit',
  'age',
  'languages',
  'travel',
  'restrictions',
  'insurance',
  'lostWages',
] as const;

const aboutFields = ['about', 'additionalNotes'] as const;

export interface PdfCoverPageProps {
  readonly data: Partial<Application>;
}

export const PdfCoverPage = ({ data }: PdfCoverPageProps) => {
  const { t } = useTranslation('common');
  const userName = getName(data);

  return (
    <Page style={[mainStyles.page, styles.page]}>
      <View fixed style={[mainStyles.header, styles.header]}>
        <Text style={styles.subject}>{data.pdfTitle}</Text>
        <Text style={styles.name}>{userName}</Text>
        {data?.avatar?.download?.file && (
          <View style={[styles.avatar]}>
            <Image style={styles.avatarImage} src={data.avatar.download.file} />
          </View>
        )}
      </View>
      <View style={[mainStyles.content, styles.content]} wrap={false}>
        <Text style={[mainStyles.h1, styles.pageTitle]}>{t('application.pdf.coverTitle')}</Text>
        <View style={styles.contentCard}>
          <View style={styles.contentCardItem}>
            {profileFields
              .filter(field => isAnswered(data[field]))
              .map((field, index) => {
                const lastChild = index === profileFields.length - 1;

                return (
                  <View key={field} style={!lastChild ? styles.contentItem : undefined}>
                    <Text style={mainStyles.label}>
                      {t(`application.field.${field}.label`, t(`application.field.${field}.subtitle`))}
                    </Text>
                    <Text style={[mainStyles.text, styles.text]}>{data[field]}</Text>
                  </View>
                );
              })}
          </View>
          <View style={[styles.contentCardItem, styles.contentCardItemAbout]}>
            <View style={mainStyles.flex1}>
              {aboutFields
                .filter(field => isAnswered(data[field]))
                .map((field, index) => {
                  const lastChild = index === aboutFields.length - 1;

                  return (
                    <View key={field} style={!lastChild ? styles.contentItem : undefined}>
                      <Text style={[mainStyles.h3, mainStyles.colorPrimary, styles.aboutTitle]}>
                        {t(`application.field.${field}.subtitle`, t(`application.field.${field}.label`))}
                      </Text>
                      <Text>{data[field]}</Text>
                    </View>
                  );
                })}
            </View>
            <View style={styles.aboutNotes}>
              <Text>{t('application.pdf.aboutNotes')}</Text>
            </View>
          </View>
        </View>
      </View>
      <View fixed style={[mainStyles.footer, styles.footer]}>
        <View>
          <Text style={[mainStyles.h3, styles.footerTitle]}>{t('application.pdf.title')}</Text>
          <Text>{t('application.pdf.description')}</Text>
        </View>
        <Image style={styles.footerLogo} src={logoSmallWhite} />
      </View>
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    paddingTop: 124,
    paddingBottom: 74,
  },
  header: {
    backgroundColor: palette.primary.main,
    color: '#FFF',
    height: 124,
    paddingVertical: 35,
  },
  subject: {
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 12,
  },
  name: {
    fontSize: 20,
    fontWeight: 600,
  },
  avatar: {
    width: 134,
    height: 134,
    backgroundColor: '#FFF',
    padding: 2,
    borderRadius: '50%',
    overflow: 'hidden',
    position: 'absolute',
    top: 25,
    right: 30,
    zIndex: 10,
  },
  avatarImage: {
    borderRadius: '50%',
    objectFit: 'cover',
    objectPosition: 'center',
    height: '100%',
    width: '100%',
  },
  content: {
    paddingTop: 40,
    paddingBottom: 20,
  },
  pageTitle: {
    marginBottom: 10,
  },
  contentCard: {
    border: `1pt solid ${palette.grey[500]}`,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 10,
    height: '100%',
  },
  contentCardItem: {
    backgroundColor: palette.primary.light,
    borderRadius: 10,
    paddingHorizontal: 20,
    paddingTop: 25,
    paddingBottom: 30,
    width: '50%',
  },
  contentCardItemAbout: {
    backgroundColor: 'transparent',
    paddingLeft: 25,
    display: 'flex',
    flexDirection: 'column',
  },
  aboutNotes: {
    color: palette.text.secondary,
    marginTop: 10,
    paddingTop: 12,
    borderTop: `0.5pt solid ${palette.grey[500]}`,
  },
  contentItem: {
    marginBottom: 15,
  },
  text: {
    maxWidth: '100%',
  },
  aboutTitle: {
    marginBottom: 7,
  },
  footer: {
    backgroundColor: palette.primary.main,
    color: '#FFF',
    height: 74,
    paddingVertical: 14,
  },
  footerTitle: {
    marginBottom: 9,
  },
  footerLogo: {
    width: 25,
  },
});
