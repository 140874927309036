import { camelCase } from 'change-case';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getName } from '@app/application/utils';
import { Box } from '@mui/system';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { Typography } from '@mui/material';
import { Card } from '@app/ui/card';
import { DynamicForm, DynamicFormConfigSurvey } from '@app/dynamic';
import { Loader } from '@app/ui/loader';
import defaultImageCard from '../images/card-questionnaire-default.jpg';
import { QuestionnaireSurveyEnum } from '../constants';
import { useUpdateQuestionnaire } from '../hooks/useUpdateQuestionnaire';
import { questionnaireConfig } from '../configs';

export const AdminQuestionnaireForm = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    id,
    survey: surveyParam,
    entity,
  } = useParams<{ id?: string; survey: string; entity: 'surrogates' | 'parent' }>();
  const survey = camelCase(surveyParam as string);

  const handleBack = useCallback(() => navigate(`/admin/${entity}/${id}`), [id, entity]);

  const config = useMemo(() => questionnaireConfig.surveys.find(item => survey === item.name), [survey]);

  const {
    surveyConfig,
    initialValues,
    isGetQuestionnaireLoading,
    isUpdateQuestionnaireLoading,
    onSubmit: onQuestionnaireSubmit,
  } = useUpdateQuestionnaire({
    config,
    id: Number(id),
    isAdmin: true,
    fetchOptions: { fetchPolicy: 'no-cache' },
    onUpdateSuccess: handleBack,
  });

  const surveyTitle = t(
    `questionnaire.field.survey.option.${survey}.name`,
    initialValues || { firstName: '', partner: { firstName: '' } },
  );

  const nameTitle = useMemo(() => {
    if (initialValues) {
      return getName(survey === QuestionnaireSurveyEnum.secondIntendedParent ? initialValues?.partner : initialValues);
    }
    return '';
  }, [initialValues]);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t(`user.page.parentAdminList.title`),
        to: `/admin/${entity}`,
      },
      {
        title: nameTitle,
        to: `/admin/${entity}/${id}`,
      },
      {
        title: surveyTitle,
      },
    ],
    [t, surveyTitle, initialValues, id, entity],
  );

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />
      <Box mb={3} display="flex" alignItems="center">
        <Typography variant="h2">{surveyTitle}</Typography>
      </Box>

      <Card size="large" img={config?.meta?.cardImage || defaultImageCard}>
        {initialValues && surveyConfig && (
          <DynamicForm
            translation="questionnaire.field"
            onSubmit={onQuestionnaireSubmit}
            onBack={handleBack}
            initialValues={initialValues || {}}
            config={
              {
                ...surveyConfig,
                pages: surveyConfig?.pages?.filter(page => {
                  return !page?.information;
                }),
              } as DynamicFormConfigSurvey
            }
          />
        )}
      </Card>

      <Loader isLoading={isGetQuestionnaireLoading || isUpdateQuestionnaireLoading} />
    </Box>
  );
};
