import { getFieldsFromSurveys } from '@app/application/utils/survey';
import { DynamicFormConfigSurvey } from '@app/dynamic';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError, QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { isVisible } from '@app/dynamic/utils';
import { extractValidationErrors } from '@app/query';
import { FormikHelpers } from 'formik';
import { omitDeep } from '@app/app/utils/omitDeep';
import { error } from '@app/snackbars';
import { Questionnaire } from '../types/Questionnaire';
import { questionnaireGetOne, questionnaireUpdateOne } from '../gql';

export interface UseUpdateQuestionnaire {
  readonly id: number;
  readonly config?: DynamicFormConfigSurvey;
  readonly onUpdateSuccess?: () => void;
  readonly isAdmin?: boolean;
  readonly withFlags?: boolean;
  readonly fetchOptions?: QueryHookOptions;
}

export const useUpdateQuestionnaire = ({
  id,
  config,
  onUpdateSuccess,
  fetchOptions = {},
  withFlags = false,
  isAdmin = false,
}: UseUpdateQuestionnaire) => {
  const { t } = useTranslation('common');

  const { surveyConfig, excludedFields, questionnaireGetOneGql, questionnaireUpdateOneGql } = useMemo(() => {
    const fields = config ? getFieldsFromSurveys([config]) : { surveyFields: [], excludedFields: [] };

    return {
      excludedFields: fields.excludedFields,
      surveyConfig: config,
      questionnaireGetOneGql: questionnaireGetOne([
        ...fields.surveyFields,
        ...(withFlags ? ['flags'] : []),
        'firstName',
        'lastName',
        'partner.id',
        'partner.firstName',
      ]),
      questionnaireUpdateOneGql: questionnaireUpdateOne(fields.surveyFields),
    };
  }, [config, withFlags]);

  const { data, loading } = useQuery<{ questionnaireGetOne: Questionnaire }>(questionnaireGetOneGql, {
    variables: { id },
    ...fetchOptions,
  });

  const isSurveyVisible = useMemo(() => {
    if (surveyConfig && data?.questionnaireGetOne) {
      return isVisible(surveyConfig, data?.questionnaireGetOne);
    }

    return true;
  }, [surveyConfig, data?.questionnaireGetOne]);

  const result = useMemo(() => {
    if (data?.questionnaireGetOne && config?.name) {
      const { flags = {}, ...initialValues } = data.questionnaireGetOne;

      return { flags: flags[config.name], initialValues: { ...initialValues, isAdmin } };
    }

    return { initialValues: undefined, flags: undefined };
  }, [data?.questionnaireGetOne, config?.name, isAdmin]);

  const [updateQuestionnaire, { loading: isUpdateQuestionnaireLoading }] = useMutation<{
    questionnaireUpdateOne: Questionnaire;
  }>(questionnaireUpdateOneGql, {
    fetchPolicy: 'no-cache',
    onCompleted: onUpdateSuccess,
  });

  const onSubmit = useCallback(
    async (input: Partial<Questionnaire>, helpers: FormikHelpers<Partial<Questionnaire>>) => {
      try {
        await updateQuestionnaire({
          variables: {
            input: omitDeep(isAdmin ? input : { enableStripErrorFields: true, ...input }, excludedFields, ['avatar']),
          },
        });
      } catch (e) {
        const errors = extractValidationErrors(e as ApolloError);

        if (Object.keys(errors).length > 0) {
          Object.entries(errors).forEach(([name, message]) => {
            helpers.setFieldError(name, message);
          });
        } else {
          error((e as ApolloError).message || t('general.error.somethingWentWrong'));
        }
      }
    },
    [t, excludedFields, isAdmin],
  );

  return {
    surveyConfig,
    onSubmit,
    isUpdateQuestionnaireLoading,
    isSurveyVisible,
    ...result,
    isGetQuestionnaireLoading: loading,
  };
};
