import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Link, Typography } from '@mui/material';

import { useAuth, useAuthActions } from '@app/auth';
import { Loader } from '@app/ui/loader';
import { Alert } from '@app/ui/alert';
import { error } from '@app/snackbars';
import { useConfirmation } from '@app/ui/modal';

import { ApplicationList } from './ApplicationList';
import { Application } from '../../types/Application';
import { APPLICATION_SUBMIT, applicationGetOne } from '../../gql';
import { applicationConfig } from '../../configs';
import { getFieldsFromSurveys } from '../../utils/survey';
import { ApplicationStatusEnum } from '../../constants';

export const UserApplicationItem = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { application: currentApplication } = useAuth();
  const { signOut, isLoading } = useAuthActions();

  const fields = useMemo(() => {
    const { surveyFields } = getFieldsFromSurveys(applicationConfig.surveys);

    return [...surveyFields, 'isPhotosSubmitted'];
  }, []);

  const { data, loading } = useQuery<{ applicationGetOne: Application }>(applicationGetOne([...fields, 'photos.id']), {
    variables: { id: currentApplication?.id },
    fetchPolicy: 'network-only',
  });

  const [applicationSubmit, { loading: isSubmitLoading }] = useMutation<{ applicationSubmit: Partial<Application> }>(
    APPLICATION_SUBMIT,
  );

  const handleSurveyClick = useCallback((survey: string) => navigate(`/surrogate-application/${survey}`), []);

  const handleSubmit = useCallback(
    async (hideModal: () => void) => {
      try {
        const response = await applicationSubmit({ variables: { input: { id: currentApplication?.id } } });

        const application = response?.data?.applicationSubmit;

        if (application?.status === ApplicationStatusEnum.disqualified) {
          await signOut();
          navigate('/disqualified');
        } else if (!!application?.status) {
          navigate('/surrogate-thank-you');
        }

        hideModal();
      } catch (e) {
        error(t('general.error.somethingWentWrong'));
      }
    },
    [t, currentApplication?.id, applicationSubmit, signOut, navigate],
  );

  const [confirm] = useConfirmation({
    title: t('application.modal.title'),
    description: t('application.modal.description'),
    submitButtonText: t('application.button.finishAndSubmit.name'),
    onSubmit: handleSubmit,
    isLoading: isSubmitLoading || isLoading,
  });

  return (
    <>
      {data?.applicationGetOne?.status &&
        ![ApplicationStatusEnum.lead, ApplicationStatusEnum.applicants].includes(
          data?.applicationGetOne?.status as ApplicationStatusEnum,
        ) &&
        (data?.applicationGetOne?.photos?.length || 0) < 8 && (
          <Alert severity="info" sx={{ mb: 4 }} inner>
            <Trans
              i18nKey={t('application.finish.upload.notification')}
              components={{
                a: <Link component={RouterLink} to="/surrogate-application/upload-photos" />,
              }}
            />
          </Alert>
        )}
      <Typography variant="h1" gutterBottom>
        {t('application.page.title')}
      </Typography>
      <Typography paragraph>{t('application.page.description', { name: currentApplication?.firstName })}</Typography>

      {data?.applicationGetOne && (
        <ApplicationList item={data.applicationGetOne} onClick={handleSurveyClick} onSubmit={confirm} />
      )}
      <Loader isLoading={loading || isSubmitLoading || isLoading} />
    </>
  );
};
