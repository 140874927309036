import { Routes, Route } from 'react-router-dom';

import { useSignedIn } from '@app/auth';
import { useScrollToTop } from '@app/app/hooks/useScrollToTop';
import { useGoogleTagManager } from '@app/app/hooks/useTagManager';
import { FacebookPixel } from '@app/analytics/components/FacebookPixel';

import { Public } from './_public';
import { Private } from './_private';
import { ChangeEmailConfirmPage } from './change-email-confirm';
import { PasswordCreatePage } from './password-create';
import { AdminSingleUserPdfPage } from './admin/single-user-pdf';
import { AdminParentSingleUserPdfPage } from './parent/single-parent-pdf';
import { SignUpPage } from './sign-up';
import { SignUpParentPage } from './sign-up-parent';
import { SignUpParentConfirmPage } from './sign-up-parent-confirm';
import { SurrogateDisqualifiedPage } from './surrogate-disqualified-page';
import { WooHooPage } from './woo-hoo';
import { SurrogateThankYou } from './surrogate-thank-you';

export const App = () => {
  const isSignedIn = useSignedIn();
  useScrollToTop();
  useGoogleTagManager();

  return (
    <>
      <FacebookPixel />
      <Routes>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/woo-hoo" element={<WooHooPage />} />
        <Route path="/surrogate-disqualified" element={<SurrogateDisqualifiedPage />} />
        <Route path="/sign-up-parent" element={<SignUpParentPage />} />
        <Route path="/change-email/:token/:entity" element={<ChangeEmailConfirmPage />} />
        <Route path="/password-create/:token/:entity" element={<PasswordCreatePage />} />
        <Route path="/admin/parents/:id/pdf/:type" element={<AdminParentSingleUserPdfPage />} />
        <Route path="/admin/surrogates/:id/pdf/:type" element={<AdminSingleUserPdfPage />} />
        <Route path="/thank-you-parent" element={<SignUpParentConfirmPage />} />
        <Route path="/surrogate-thank-you" element={<SurrogateThankYou />} />
        <Route path="*" element={isSignedIn ? <Private /> : <Public />} />
      </Routes>
    </>
  );
};
