import { Routes, Route, Navigate } from 'react-router-dom';

import { SignInPage } from './sign-in';
import { SignInParentPage } from './sign-in-parent';
import { SignUpConfirmPage } from './sign-up-confirm';
import { PasswordResetPage } from './password-reset';
import { PasswordResetParentPage } from './password-reset-parent';
import { DisqualifiedPage } from './disqualified';

export const Public = () => {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-in-parent" element={<SignInParentPage />} />
      <Route path="/sign-up/:token" element={<SignUpConfirmPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/password-reset-parent" element={<PasswordResetParentPage />} />
      <Route path="/disqualified" element={<DisqualifiedPage />} />
      <Route path="*" element={<Navigate to="/sign-in" />} />
    </Routes>
  );
};
