import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useAuth } from '@app/auth';
import { QUESTIONNAIRE_SUBMIT_PHOTOS, questionnaireGetOne } from '@app/questionnaire/gql';
import { Questionnaire } from '@app/questionnaire/types/Questionnaire';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { Card } from '@app/ui/card';
import { Breadcrumbs } from '@app/ui/breadcrumbs';
import { QuestionnaireStatusEnum } from '@app/questionnaire/constants';
import { Loader } from '@app/ui/loader';
import { error, success } from '@app/snackbars';
import imgUploadCard from '../../images/card-questionnaire-upload-page.jpg';
import { QuestionnaireUploadForm } from './QuestionnaireUploadForm';

export const QuestionnaireUserUploadPhotos = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const { questionnaire } = useAuth();

  const { data, loading } = useQuery<{ questionnaireGetOne: Questionnaire }>(
    questionnaireGetOne(['isPhotosSubmitted', 'status', 'photos.name']),
    {
      variables: { id: questionnaire?.id },
    },
  );

  const [questionnaireSubmitPhotos, { loading: isSubmitLoading }] = useMutation<{
    questionnaireSubmitPhotos: Partial<Questionnaire>;
  }>(QUESTIONNAIRE_SUBMIT_PHOTOS);

  const breadcrumbs = useMemo(
    () => [
      {
        title: t('questionnaire.page.title'),
        to: '/parent-questionnaire',
      },
      {
        title: t('questionnaire.finish.upload.title'),
      },
    ],
    [t],
  );

  const handleBack = useCallback(() => navigate('/parent-questionnaire'), []);

  const handleFinish = useCallback(async () => {
    try {
      await questionnaireSubmitPhotos({ variables: { input: { id: questionnaire?.id } } });

      handleBack();

      success(t('application.finish.upload.successMessage'));
    } catch (e) {
      error((e as ApolloError).message || t('general.error.somethingWentWrong'));
    }
  }, [t, questionnaire?.id]);

  return (
    <Box sx={{ maxWidth: 670, mx: 'auto' }}>
      <Breadcrumbs options={breadcrumbs} />

      <Card size="large" img={imgUploadCard}>
        <Typography variant="h1" gutterBottom>
          {t('questionnaire.finish.upload.title')}
        </Typography>
        <Box mb={3}>
          <Typography>{t('questionnaire.finish.upload.description.item1')}</Typography>
        </Box>
        {data?.questionnaireGetOne?.id && (
          <>
            <QuestionnaireUploadForm questionnaireId={data.questionnaireGetOne.id} />

            <Box sx={{ display: { sm: 'flex' }, justifyContent: 'flex-end', mt: 4 }}>
              <Button
                disabled={
                  data?.questionnaireGetOne?.status === QuestionnaireStatusEnum.applicants ||
                  (data?.questionnaireGetOne?.photos?.length || 0) < 6
                }
                sx={{ width: { xs: '100%', sm: 'auto' } }}
                variant="contained"
                color="primary"
                onClick={handleFinish}
              >
                {t('questionnaire.button.confirmAndFinish.name')}
              </Button>
            </Box>
          </>
        )}
      </Card>

      <Box mt={3}>
        <Button color="primary" onClick={handleBack}>
          {t('questionnaire.form.button.backToAll')}
        </Button>
      </Box>

      <Loader isLoading={loading || isSubmitLoading} />
    </Box>
  );
};
