import dayJs from 'dayjs';

import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';
import { yup } from '@app/formik';
import { USA_STATES } from '@app/user/constants/usaStates';
import { COUNTRIES } from '@app/application/constants/countries';
import { SexualOrientationEnum } from '@app/application/constants';
import { isPhoneValid } from '@app/auth/utils';
import {
  EnglishLevelEnum,
  GenderIdentityEnum,
  PronounsEnum,
  QuestionnaireDiagnosisEnum,
  QuestionnairePdf,
  QuestionnaireRelationshipStatusEnum,
  QuestionnaireSurveyEnum,
} from '../constants';
import cardImage from '../images/card-questionnaire-second-intended-parent.jpg';

export const secondIntendedParent: DynamicFormConfigSurvey<QuestionnaireSurveyEnum> = {
  name: QuestionnaireSurveyEnum.secondIntendedParent,
  meta: {
    cardImage,
    dependOnFields: ['status', 'isPartnerInformation', 'partner.firstName'],
  },
  visibleIf: ({ isPartnerInformation }) => Boolean(isPartnerInformation),
  pages: [
    {
      name: `${QuestionnaireSurveyEnum.secondIntendedParent}.information`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            variables: {
              time: '5-10',
            },
            template: [['description.item1', 'description.item2', 'description.item3']],
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part1`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part1`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'partner.name',
      alias: 'name',
      elements: [
        {
          name: 'partner.firstName',
          alias: 'legalFirstName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 50,
          },
        },
        {
          optional: true,
          name: 'partner.middleName',
          alias: 'middleName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            length: 50,
            withLockIcon: true,
          },
        },
        {
          name: 'partner.lastName',
          alias: 'legalLastName',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            length: 50,
            withLockIcon: true,
          },
        },
        {
          optional: true,
          name: 'partner.nickname',
          alias: 'nickname',
          type: DynamicFormElementType.text,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 50,
          },
        },
      ],
    },
    {
      name: 'partner.cellPhone',
      alias: 'cellPhone',
      elements: [
        {
          name: 'partner.phone',
          alias: 'phone',
          type: DynamicFormElementType.phone,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => value.length >= 12,
          validation: (value: any, t: (key: string) => string) => {
            try {
              yup
                .string()
                .nullable()
                .test(
                  'optional-or-phone',
                  t('general.validation.phoneNumber') as string,
                  isPhoneValid(t, 'general.validation.phoneNumber'),
                )
                .validateSync(value);
              return undefined;
            } catch (error) {
              return (error as Error).message;
            }
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            disableDropdown: false,
            forceCallingCode: false,
            withLockIcon: true,
            length: 13,
          },
        },
      ],
    },
    {
      name: 'partner.email',
      alias: 'email',
      elements: [
        {
          name: 'partner.email',
          alias: 'contactEmail',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          validIf: value => yup.string().email().isValidSync(value),
          validation: (value: any, t: (key: string) => string) => {
            try {
              const ans = yup.string().email().isValidSync(value);
              if (!ans) {
                throw new Error(t('general.validation.email'));
              }
              return undefined;
            } catch (error) {
              return (error as Error).message;
            }
          },
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            withLockIcon: true,
            capitalize: false,
          },
        },
      ],
    },
    {
      name: 'dateOfBirthAndAge',
      elements: [
        {
          name: 'partner.dateOfBirth',
          alias: 'dateOfBirth',
          type: DynamicFormElementType.date,
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            withLockIcon: true,
            openTo: 'year',
            minDate: dayJs().subtract(50, 'years'),
            maxDate: dayJs().subtract(10, 'years'),
            views: ['year', 'month', 'day'],
          },
        },
        {
          optional: true,
          name: 'partner.age',
          alias: 'age',
          type: DynamicFormElementType.computedText,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            disabled: true,
            formula: ({ partner }) => {
              return partner?.dateOfBirth && partner?.dateOfBirth !== 'Invalid Date'
                ? dayJs().diff(dayJs(partner?.dateOfBirth).subtract(1, 'day'), 'y')
                : '-';
            },
          },
        },
      ],
    },
    {
      name: 'place',
      elements: [
        {
          name: 'partner.placeOfBirth',
          alias: 'placeOfBirth',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 100,
          },
        },
      ],
    },
    {
      name: 'address',
      elements: [
        {
          name: 'partner.isAddressSameWithPartner',
          alias: 'isAddressSameWithPartner',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'partner.country',
          alias: 'country',
          type: DynamicFormElementType.autocomplete,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner?.isAddressSameWithPartner),
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            options: COUNTRIES,
          },
        },
        {
          name: 'partner.state',
          alias: 'state',
          type: DynamicFormElementType.autocomplete,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner?.isAddressSameWithPartner) && partner?.country === 'US',
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            options: USA_STATES,
          },
        },
        {
          name: 'partner.fullAdress',
          alias: 'fullAdress',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => !Boolean(partner?.isAddressSameWithPartner),
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            withLockIcon: true,
            length: 300,
          },
        },
      ],
    },
    {
      name: 'employmentStatus',
      elements: [
        {
          name: 'partner.isEmployed',
          alias: 'isEmployed',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'partner.occupation',
          alias: 'occupation',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.isEmployed),
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 100,
          },
        },
        {
          name: 'partner.employer',
          alias: 'employer',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.isEmployed),
          meta: {
            pdf: {
              [QuestionnairePdf.agency]: true,
            },
          },
          props: {
            withLockIcon: true,
            length: 100,
          },
        },
      ],
    },
    {
      name: 'english',
      elements: [
        {
          name: 'partner.englishLevel',
          alias: 'englishLevel',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.half,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            enum: EnglishLevelEnum,
          },
        },
        {
          name: 'partner.languages',
          alias: 'languages',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 300,
          },
        },
      ],
    },
    {
      name: 'gender',
      elements: [
        {
          name: 'partner.genderIdentity',
          alias: 'genderIdentity',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            enum: GenderIdentityEnum,
          },
        },
        {
          name: 'partner.genderIdentityDescription',
          alias: 'genderIdentityDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner?.genderIdentity === GenderIdentityEnum.other,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 300,
          },
        },
        {
          name: 'partner.pronouns',
          alias: 'pronouns',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            enum: PronounsEnum,
          },
        },
        {
          name: 'partner.pronounsDescription',
          alias: 'pronounsDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner?.pronouns === PronounsEnum.other,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 300,
          },
        },
      ],
    },
    {
      name: 'sexual',
      elements: [
        {
          name: 'partner.sexualOrientation',
          alias: 'sexualOrientation',
          showDescription: true,
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            enum: SexualOrientationEnum,
          },
        },
        {
          name: 'partner.sexualOrientationDescription',
          alias: 'sexualOrientationDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner?.sexualOrientation === SexualOrientationEnum.other,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 300,
          },
        },
      ],
    },
    {
      name: 'relationship',
      elements: [
        {
          name: 'partner.relationshipStatus',
          alias: 'relationshipStatus',
          type: DynamicFormElementType.enumSelect,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            enum: QuestionnaireRelationshipStatusEnum,
          },
        },
        {
          name: 'partner.relationshipStatusDescription',
          alias: 'relationshipStatusDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => partner?.relationshipStatus === QuestionnaireRelationshipStatusEnum.other,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            length: 300,
          },
        },
      ],
    },
    {
      name: 'isAnyOtherChildren',
      elements: [
        {
          noLabel: true,
          name: 'partner.isAnyOtherChildren',
          alias: 'isAnyOtherChildren',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            defaultChecked: false,
          },
        },
        {
          name: 'partner.otherChildren',
          alias: 'otherChildren',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.isAnyOtherChildren),
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'isArrested',
      elements: [
        {
          noLabel: true,
          name: 'partner.isArrested',
          alias: 'isArrested',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            defaultChecked: false,
            reversed: true,
          },
        },
        {
          name: 'partner.arrestDescription',
          alias: 'arrestDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.isArrested),
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part2`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part2`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header'],
          },
        },
      ],
    },
    {
      name: 'personalityDescription',
      elements: [
        {
          noLabel: true,
          name: 'partner.personalityDescription',
          alias: 'personalityDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'interests',
      elements: [
        {
          noLabel: true,
          name: 'partner.interests',
          alias: 'interests',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'favoriteGeneral',
      elements: [
        {
          noLabel: true,
          name: 'partner.favoriteGeneral',
          alias: 'favoriteGeneral',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'favoriteFood',
      elements: [
        {
          noLabel: true,
          name: 'partner.favoriteFood',
          alias: 'favoriteFood',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'religion',
      elements: [
        {
          noLabel: true,
          name: 'partner.religion',
          alias: 'religion',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part3`,
      information: true,
      elements: [
        {
          name: `${QuestionnaireSurveyEnum.firstIntendedParent}.information.part3`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            translation: 'questionnaire',
            template: ['header', ['description.item1', 'description.item2']],
          },
        },
      ],
    },
    {
      name: 'hasHospitalizedForPsychiatric',
      elements: [
        {
          noLabel: true,
          name: 'partner.hasHospitalizedForPsychiatric',
          alias: 'hasHospitalizedForPsychiatric',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'partner.hospitalizationDescription',
          alias: 'hospitalizationDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.hasHospitalizedForPsychiatric),
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'haveAlcoholDrugIllnessHistory',
      elements: [
        {
          noLabel: true,
          name: 'partner.haveAlcoholDrugIllnessHistory',
          alias: 'haveAlcoholDrugIllnessHistory',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'partner.alcoholDrugIllnessDescription',
          alias: 'alcoholDrugIllnessDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.haveAlcoholDrugIllnessHistory),
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'haveHealthIssues',
      elements: [
        {
          noLabel: true,
          name: 'partner.haveHealthIssues',
          alias: 'haveHealthIssues',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            reversed: true,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
        {
          name: 'partner.healthIssuesDescription',
          alias: 'healthIssuesDescription',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ partner }) => Boolean(partner?.haveHealthIssues),
          props: {
            multiline: true,
            length: 1000,
          },
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
        },
      ],
    },
    {
      name: 'yourDiagnosis',
      elements: [
        {
          name: 'partnerDiagnosis',
          // alias: 'yourDiagnosis',
          type: DynamicFormElementType.toggleButtonGroup,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            json: true,
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            translation: 'questionnaire',
            options: Object.values(QuestionnaireDiagnosisEnum),
          },
        },
        {
          name: 'partnerDiagnosisDetails',
          alias: 'yourDiagnosisDetails',
          type: DynamicFormElementType.text,
          visibleIf: ({ partnerDiagnosis }) => partnerDiagnosis && Object.values(partnerDiagnosis).some(Boolean),
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [QuestionnairePdf.surrogates]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
  ],
};
