import { DynamicFormConfigSurvey, DynamicFormElementType, DynamicFormElementWidthMode } from '@app/dynamic';

import {
  ApplicationPdf,
  ApplicationSurveyEnum,
  EmbryoTransferTypeEnum,
  IntendedParentContactTypeEnum,
  IntendedParentTypeEnum,
  NumberOfBabiesEnum,
} from '../constants';
import cardImage from '../images/card-application-surrogacy-journey.jpg';

export const surrogacyJourney: DynamicFormConfigSurvey<ApplicationSurveyEnum> = {
  name: ApplicationSurveyEnum.surrogacyJourney,
  meta: {
    cardImage,
    dependOnFields: ['status', 'firstName', 'lastName'],
  },
  pages: [
    {
      name: `${ApplicationSurveyEnum.surrogacyJourney}.information`,
      information: true,
      elements: [
        {
          name: `${ApplicationSurveyEnum.surrogacyJourney}.information`,
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            variables: {
              time: '20',
            },
            template: [
              ['description.item1', 'description.item2', 'description.item3', 'description.item4', 'description.item5'],
            ],
          },
        },
      ],
    },
    {
      name: 'surrogateReasons',
      elements: [
        {
          name: 'surrogateReasons',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'nearbySupport',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
      ],
    },
    {
      name: 'medicationAgreement',
      elements: [
        {
          name: 'medicationAgreement.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'medicationAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'noBodyModificationsAgreement',
      elements: [
        {
          name: 'noBodyModificationsAgreement',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'noCosmeticProceduresAgreement',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'noSubstancesAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'substanceTestingAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'communicationAgreement',
      elements: [
        {
          name: 'communicationAgreement.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'communicationAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'schedulingFlexibilityAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'understandingOfCommunicationRequirements',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'embryoTransferType',
      elements: [
        {
          name: 'embryoTransferType',
          showDescription: true,
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: EmbryoTransferTypeEnum,
          },
        },
        {
          name: 'carryTwinsAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'maximumNumberOfBabies',
          type: DynamicFormElementType.enumRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: NumberOfBabiesEnum,
          },
        },
      ],
    },
    {
      name: 'tripletsToTwinsReduction',
      elements: [
        {
          name: 'tripletsToTwinsReduction.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'tripletsToTwinsReduction',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'twinsToSingletonReduction',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'terminateIfLifeAtRisk',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'terminateIfBabyNotSurvive',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'terminateIfAbnormality',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'terminateIfAbnormalityDetails',
          alias: 'pleaseExplain',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          visibleIf: ({ terminateIfAbnormality }) => terminateIfAbnormality === false,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'terminateIfDownSyndrome',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'intendedParentRelationship',
      elements: [
        {
          name: 'intendedParentRelationship',
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'intendedParentInRoomAgreement',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'intendedParentPostContact',
          showDescription: true,
          type: DynamicFormElementType.enumCheckbox,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: IntendedParentContactTypeEnum,
          },
        },
        {
          name: 'intendedParentType',
          showDescription: true,
          type: DynamicFormElementType.enumCheckbox,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            enum: IntendedParentTypeEnum,
          },
        },
      ],
    },
    {
      name: 'ableToTravelForIvf',
      elements: [
        {
          name: 'ableToTravelForIvf',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            length: 1000,
          },
        },
        {
          name: 'undergoAmniocentesisAgreement',
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
        {
          name: 'pumpBreastMilkAgreement',
          showDescription: true,
          type: DynamicFormElementType.booleanRadio,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
        },
      ],
    },
    {
      name: 'personalNote',
      elements: [
        {
          name: 'personalNote.information',
          type: DynamicFormElementType.information,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            pageInner: true,
            template: ['text'],
          },
        },
        {
          name: 'personalNote',
          showDescription: true,
          type: DynamicFormElementType.text,
          widthMode: DynamicFormElementWidthMode.full,
          meta: {
            pdf: {
              [ApplicationPdf.general]: true,
            },
          },
          props: {
            multiline: true,
            rows: 6,
            length: 2000,
          },
        },
        {
          name: 'avatar.name',
          alias: 'avatar',
          showDescription: true,
          type: DynamicFormElementType.upload,
          widthMode: DynamicFormElementWidthMode.full,
          props: {
            cropperProps: {
              cropShape: 'round',
            },
            type: 'applicationAvatar',
          },
        },
      ],
    },
  ],
};
