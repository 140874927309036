import * as yup from 'yup';

export const isPhoneValid = (t: (key: string) => string, validationKey: string) => {
  return function (this: yup.TestContext, value: string | null | undefined): boolean | yup.ValidationError {
    if (!value || value.length === 0) {
      return true;
    }
    try {
      yup.string().phone('USA', false).validateSync(value);
      return true;
    } catch (err) {
      return this.createError({ message: t(validationKey) as string });
    }
  };
};

export const isPasswordStrong = (value: string | undefined) => {
  return Boolean(
    value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])\S{8,99}$/.test(value),
  );
};
